import { createPinia } from "pinia"
import { observe } from "selector-observer"
import { createApp } from "vue"
import TrackPlayer from "./TrackPlayer.vue"

observe("#track-player", function(el: HTMLElement) {
  const isLoggedIn = el.dataset.isLoggedIn === "true"
  const app = createApp(TrackPlayer)
  app.use(createPinia())
  app.provide("isLoggedIn", isLoggedIn)
  app.mount(el)
})
