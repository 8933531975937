<script lang="ts" setup>
import { ref, computed } from "vue"
import { usePlayerStore } from "~/stores/player-store"

const playerStore = usePlayerStore()
const draggingValue = ref(0)
const isDragging = ref(false)
const input = ref<HTMLInputElement | null>(null)

function onMouseDown() {
  isDragging.value = true
  draggingValue.value = playerStore.currentTime
}

function scrubTo() {
  playerStore.scrubTo(input.value.valueAsNumber)
  isDragging.value = false
}

const value = computed(() => {
  if (isDragging.value) {
    return draggingValue.value
  } else {
    return playerStore.currentTime
  }
})
</script>

<template>
  <input
    ref="input"
    type="range"
    class="w-full mx-1 h-px"
    min="0"
    :max="playerStore.duration"
    :value="isDragging ? draggingValue : value"
    @mousedown="onMouseDown"
    @change="scrubTo"
  />
</template>
