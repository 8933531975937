/*       */

import { on } from "delegated-events"

on("click", ".js-show-version-comment-composer", function(e: Event) {
  const target = e.target as HTMLElement
  const container = target.closest(".js-version-comment-container")
  const composer = container.querySelector<HTMLElement>(".js-version-comment-composer")
  if (composer) {
    composer.hidden = false
  }
  target.hidden = true
})

on("ajax:success", ".js-version-comment-form", function(e: CustomEvent) {
  const form = e.target as HTMLFormElement
  const container = form.closest(".js-version-comment-container")
  const list = container.querySelector<HTMLElement>(".js-version-comments")

  list.insertAdjacentHTML("beforeend", e.detail[2].responseText)
  form.reset()
})
