<script lang="ts" setup>
import { computed } from "vue"
import { humanizedTime } from "~/utils/time"
import { usePlayerStore } from "~/stores/player-store"
import TrackPlayerScrubber from "./TrackPlayerScrubber.vue"
import TrackPlayerVolume from "./TrackPlayerVolume.vue"

const playerStore = usePlayerStore()

const currentTimeString = computed(() => humanizedTime(playerStore.currentTime))
const currentDurationString = computed(() => humanizedTime(playerStore.duration))

function togglePlaying() {
  if (playerStore.isPlaying) {
    playerStore.pause()
  } else {
    playerStore.play()
  }
}

const version = computed(() => playerStore.currentlyPlayingVersion)
</script>

<template>
  <div
    :class="playerStore.currentlyPlayingVersion ? 'max-h-24' : 'max-h-0'"
    class="inset-x-0 bottom-0 fixed xmax-h-0 transition-[max-height] z-[1000]"
  >
    <div class="border-top bg-zinc-100 dark:bg-zinc-900 dark:sm:bg-black pl-3 pr-2 pt-2 pb-3">
      <div class="flex items-center justify-end">
        <button
          class="xjs-track-play shadow-md active:scale-95 mr-3 p-3 w-12 h-12 rounded-full text-white flex align-center bg-blue-500"
          @click="togglePlaying"
        >
          <svg
            v-if="playerStore.isPlaying"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-pause-fill pl-0.5"
            viewBox="0 0 16 16"
          >
            <path
              d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-play-fill pl-0.5"
            viewBox="0 0 16 16"
          >
            <path
              d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
            />
          </svg>
        </button>
        <small class="mr-1 text-zinc-500 font-mono">{{ currentTimeString }}</small>
        <TrackPlayerScrubber />
        <small class="mx-1 text-zinc-500 font-mono">{{ currentDurationString }}</small>
        <TrackPlayerVolume />
        <div class="w-1/4 text-zinc-500 ml-1" style="line-height: 1rem">
          <small
            ><b class="mb-0">{{ version && version.track.name }}</b></small
          >
          <br />
          <small class="my-0 text-nowrap">Version {{ version && version.number }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
