<script setup lang="ts">
import BPMModule from "./BPMModule.vue"
import KeyModule from "./KeyModule.vue"
import { defineProps, onMounted, reactive, provide } from "vue"
import { graphql } from "~/utils/graphql"

const props = defineProps<{
  trackId: string
  isAdmin: boolean
}>()

const track = reactive({
  bpm: null,
  key: null,
})

provide("isAdmin", props.isAdmin)

onMounted(async () => {
  const response = await graphql(
    `
      query TrackQuery($trackId: ID!) {
        track(id: $trackId) {
          ... on Track {
            id
            key
            bpm
          }
        }
      }
    `,
    {
      trackId: props.trackId,
    }
  )

  track.bpm = response.data.track.bpm
  track.key = response.data.track.key
})
</script>

<template>
  <div class="js-track-sidebar">
    <div class="mt-3 flex justify-between space-x-4">
      <BPMModule :track-id="trackId" :current-bpm="track.bpm" />
      <KeyModule :current-key="track.key" :track-id="trackId" />
    </div>
  </div>
</template>
