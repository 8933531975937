/*       */
import { observe } from "selector-observer"

observe(".js-superlative", function(el) {
  var i = 0
  const superlatives = ["good", "great", "amazing", "mindblowing", "🔥", "great"]
  const timer = setInterval(function() {
    el.textContent = superlatives[i++ % superlatives.length]
    if (i >= superlatives.length) {
      clearInterval(timer)
    }
  }, 1500)
})
