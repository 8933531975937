import Sortable from "sortablejs"
import { observe } from "selector-observer"
import { fetch, fetchForm } from "~/utils/fetch"
import { on } from "delegated-events"
import { refresh } from "./refresh"

document.addEventListener("clipboard-copy", function(event) {
  const el = event.target as HTMLElement
  const button = el.querySelector("button")
  const width = button.offsetWidth
  button.style.width = `${width}px`
  const defaultText = button.querySelector<HTMLElement>(".js-default")
  const activeText = button.querySelector<HTMLElement>(".js-active")
  defaultText.hidden = true
  activeText.hidden = false
  setTimeout(() => {
    defaultText.hidden = false
    activeText.hidden = true
  }, 1000)
})

on("submit", ".js-remove-from-folder", async function(e: Event) {
  e.preventDefault()

  const form = e.target as HTMLFormElement
  const container = form.closest<HTMLElement>(".js-track-list-item")
  container.hidden = true

  try {
    await fetchForm(form)
    container.remove()
  } catch {
    container.hidden = false
  }
})

on("modal:close", ".js-picker-button", async function(e: Event) {
  const el = e.target as HTMLElement
  refresh(el.closest(".js-picker-button"))
})

on("change", ".js-folder-checkbox", async function(e: Event) {
  const el = e.target as HTMLInputElement
  const form = el.closest("form")
  await fetchForm(form)
})

on("change", ".fetchForm", async function(event) {
  const form = (event.target as HTMLElement).closest("form")
  await fetchForm(form)
})

observe(".js-folder-tracks", {
  add(el: HTMLElement) {
    Sortable.create(el, {
      animation: 150,
      onUpdate: async () => {
        let ids = [...el.querySelectorAll<HTMLElement>(".js-dashboard-track")].map(el =>
          el.getAttribute("data-id")
        )

        let params = {
          folder: {
            ordered_track_ids: ids,
          },
        }

        let url = el.getAttribute("data-update-url")
        await fetch(url, {
          body: JSON.stringify(params),
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
          },
        })
      },
    })
  },
})
