<script setup lang="ts">
import SidebarModule from "./SidebarModule.vue"
import { ref, defineProps, toRef, watch } from "vue"
import { graphql } from "~/utils/graphql"
const props = defineProps<{
  currentBpm: number | null
  trackId: string
}>()

const bpm = toRef(props, "currentBpm")
watch(bpm, newValue => {
  newBpm.value = newValue
})
const newBpm = ref(bpm.value)
const isSaving = ref(false)

async function onEdit(newValue) {
  if (newValue) {
    newBpm.value = newValue
  } else {
    newBpm.value = null
  }

  isSaving.value = true
  await graphql(
    `
    mutation SaveBPM {
      setTrackBPM(trackID: "${props.trackId}", bpm: ${newBpm.value}) {
        id
        bpm
      }
    }
  `,
    {}
  )

  isSaving.value = false
}
</script>

<template>
  <SidebarModule
    :value="bpm ? String(bpm) : null"
    label="BPM"
    input-type="number"
    @on-edit="onEdit"
  >
    <span v-if="newBpm" class="text-black dark:text-white" :class="{ 'text-zinc-400': isSaving }">
      {{ newBpm }}
    </span>
    <span v-else class="text-zinc-400">—</span>
  </SidebarModule>
</template>
