import { createPopper, Placement, PositioningStrategy } from "@popperjs/core"
import { observe } from "selector-observer"
import { fire } from "delegated-events"

observe("[data-popover]", {
  add: function(el: HTMLElement) {
    const target = el.querySelector("[data-popover-target]")
    const content = el.querySelector<HTMLElement>("[data-popover-content]")

    const placement: Placement = (el.getAttribute("data-popover-placement") || "auto") as Placement
    const strategy: PositioningStrategy = (el.getAttribute("data-popover-strategy") ||
      "absolute") as PositioningStrategy

    if (!target || !content) {
      return
    }

    const popperInstance = createPopper(target, content, {
      strategy: strategy,
      placement: placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    })

    target.addEventListener("click", function() {
      content.toggleAttribute("hidden")

      const autofocusInput = content.querySelector("[autofocus]")
      if (
        autofocusInput instanceof HTMLInputElement ||
        autofocusInput instanceof HTMLTextAreaElement
      ) {
        autofocusInput.focus()
      }

      if (content.hasAttribute("hidden")) {
        fire(el, "popover:hide")
      } else {
        fire(el, "popover:show")
      }
      popperInstance.update()
    })
  },
})

document.addEventListener("click", function(e: Event) {
  const target = e.target as HTMLElement
  if (target.closest("[data-popover]")) {
    return
  }

  document.querySelectorAll("[data-popover-content]").forEach(function(el: HTMLElement) {
    el.hidden = true
  })
})
