import { fire, on } from "delegated-events"

export function showModal(modal: HTMLElement) {
  modal.classList.add("opacity-0")
  modal.classList.remove("opacity-100")
  modal.hidden = false
  setTimeout(function() {
    modal.classList.remove("opacity-0")
    modal.classList.add("opacity-100")
  }, 10)
}

export function dismissModal(modal: HTMLElement) {
  modal.classList.add("opacity-0")
  modal.classList.remove("opacity-100")

  setTimeout(function() {
    modal.hidden = true
  }, 200)

  fire(modal, "modal:close")
}

on("click", "[data-modal-trigger]", function(e: Event) {
  const target = e.target as HTMLElement
  const trigger = target.closest("[data-modal-trigger]") as HTMLElement
  const container = trigger.closest<HTMLElement>("[data-modal-container]")
  const modal = container.querySelector<HTMLElement>("[data-modal]")
  showModal(modal)
})

on("click", "[data-modal-backdrop]", function(e: Event) {
  const target = e.target as HTMLElement
  const modal = target.closest("[data-modal]") as HTMLElement

  dismissModal(modal)
})
