<script setup lang="ts">
import { ref, defineEmits, defineProps, inject, watch } from "vue"

const props = defineProps<{
  label: string
  inputType: string
  value: string | null
}>()

const isEditing = ref(false)
const input = ref(null)
const newValue = ref(props.value)
const isAdmin = inject("isAdmin")

watch(props, nv => {
  newValue.value = nv.value
})

const emit = defineEmits(["onEdit"])

function toggleEdit() {
  isEditing.value = !isEditing.value

  if (isEditing.value) {
    setTimeout(() => {
      input.value.focus()
    }, 0)
  }
}

function onComplete() {
  isEditing.value = false
  emit("onEdit", newValue.value)
}
</script>

<template>
  <div class="basis-1/2 border dark:border-zinc-700 rounded shadow-sm py-2 px-3">
    <div class="flex justify-between items-center">
      <span class="text-zinc-500">{{ label }}</span>
      <button v-if="isAdmin" class="text-sm text-zinc-400" @click="toggleEdit">
        <i class="bi bi-pencil"></i>
      </button>
    </div>
    <input
      v-if="isEditing"
      ref="input"
      v-model="newValue"
      :type="inputType"
      class="text-input h-8 w-full text-lg"
      @keyup.enter="onComplete"
      @blur="onComplete"
    />
    <h4 v-else class="text-xl h-8 w-full">
      <slot />
    </h4>
  </div>
</template>
