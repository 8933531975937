export function humanizedTime(d: number): string {
  if (!d) {
    return "0:00"
  }

  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  let result: string
  if (h > 0) {
    result = `${`0${h}`.slice(-2)}:${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`
  } else {
    result = `${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`
  }

  return result.replace(/^0(\d):/, function(_, a) {
    return `${a}:`
  })
}
