import { createPinia } from "pinia"
import { observe } from "selector-observer"
import { createApp } from "vue"
import PlayButton from "./PlayButton.vue"

observe(".js-track-play", function(el: HTMLElement) {
  const isLoggedIn = el.dataset.isLoggedIn === "true"
  const trackID = el.dataset.id
  const versionUUID = el.dataset.uuid
  const infoURL = el.dataset.info
  const isBlurred = el.dataset.blur === "true"
  const isCurrent = el.dataset.isCurrent === "true"
  const app = createApp(PlayButton, {
    trackID,
    versionUUID,
    infoURL,
    isBlurred,
    isCurrent,
  })
  app.use(createPinia())
  app.provide("isLoggedIn", isLoggedIn)
  app.mount(el)
})
