<script lang="ts" setup>
import { computed } from "vue"
import { usePlayerStore } from "~/stores/player-store"

const playerStore = usePlayerStore()

const icon = computed(() => {
  if (playerStore.volume < 1) {
    return "bi-volume-mute-fill"
  }

  if (playerStore.volume < 100) {
    return "bi-volume-down-fill"
  }

  return "bi-volume-up-fill"
})
</script>
<template>
  <div
    data-popover
    data-popover-placement="top"
    data-popover-strategy="fixed"
    class="d-none d-sm-block"
  >
    <div data-popover-content hidden class="text-center bg-white p-4 border rounded">
      <input
        v-model="playerStore.volume"
        min="0"
        max="100"
        type="range"
        class="w-px h-[128px] bg-zinc-800"
        orient="vertical"
      />
    </div>
    <button type="button" class="btn-plain" data-popover-target>
      <i class="bi text-zinc-500 px-2" :class="icon" style="font-size: 1.4rem;"></i>
    </button>
  </div>
</template>
