<script setup lang="ts">
import SidebarModule from "./SidebarModule.vue"
import { defineProps, ref, toRef, watch } from "vue"
import { graphql } from "~/utils/graphql"
const props = defineProps<{
  currentKey: string | null
  trackId: string
}>()

const key = toRef(props, "currentKey")
watch(key, newValue => {
  newKey.value = newValue
})
const newKey = ref(key.value)
const isSaving = ref(false)

async function onEdit(newValue) {
  if (newValue) {
    newKey.value = newValue
  } else {
    newKey.value = null
  }

  isSaving.value = true
  await graphql(
    `
    mutation SaveKey {
      setTrackKey(trackID: "${props.trackId}", key: "${newKey.value}") {
        id
        key
      }
    }
  `,
    {}
  )

  isSaving.value = false
}
</script>

<template>
  <SidebarModule label="Key" :value="newKey" input-type="text" @on-edit="onEdit">
    <span v-if="newKey" class="text-black dark:text-white" :class="{ 'text-zinc-400': isSaving }">{{
      newKey
    }}</span>
    <span v-else class="text-zinc-400">—</span>
  </SidebarModule>
</template>
