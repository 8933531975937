import { fire, on } from "delegated-events"
import { fetchForm } from "~/utils/fetch"
import Cookie from "js-cookie"

function enable(button: HTMLButtonElement) {
  document.body.classList.add("dark")
  document.body.classList.add("bg-zinc-900")
  document.body.classList.remove("bg-sky-100")
  button.textContent = "Disable dark mode"
}

function disable(button: HTMLButtonElement) {
  document.body.classList.remove("dark")
  document.body.classList.remove("bg-zinc-900")
  document.body.classList.add("bg-sky-100")
  button.textContent = "Enable dark mode"
}

on("click", ".js-logged-out-dark-mode-toggle", function(e: Event) {
  const target = e.target as HTMLElement
  const button = target.closest("button")
  const darkMode = Cookie.get("dark-mode") === "true"
  Cookie.set("dark-mode", !darkMode)

  if (darkMode) {
    disable(button)
  } else {
    enable(button)
  }

  document.body.classList.toggle("dark", !darkMode)
})

on("submit", ".js-dark-mode-form", async function(e: Event) {
  e.preventDefault()

  const form = e.target as HTMLFormElement
  const button = form.querySelector<HTMLInputElement>(".js-dark-mode-button")
  const input = form.querySelector<HTMLInputElement>(".js-dark-mode-input")

  if (input.value === "1") {
    enable(button)
  } else {
    disable(button)
  }

  await fetchForm(form)

  if (input.value === "1") {
    input.value = "0"
  } else {
    input.value = "1"
  }
})

document.addEventListener("keypress", function(e) {
  if (e.key === "d" && e.ctrlKey) {
    const el = document.querySelector(".js-dark-mode-form")
    fire(el, "submit")
  }
})
