type Playlist = {
  parent: HTMLElement
  elements: Array<HTMLElement>
  currentIndex: number
}

let currentPlaylist: Playlist | null = null

export function setPlaylist(from: HTMLElement) {
  if (currentPlaylist) {
    if (currentPlaylist.parent === from) {
      return
    }
  }

  const container = from.closest<HTMLElement>(".js-playlist")
  const item = from.closest<HTMLElement>("[data-playlist-item]")

  if (!container || !item) {
    return
  }

  const elements = Array.from(container.querySelectorAll<HTMLElement>("[data-playlist-item]"))
  currentPlaylist = {
    parent: container,
    elements: elements,
    currentIndex: elements.indexOf(item),
  }
}

export function playNextPlaylistItem(): HTMLElement | null {
  if (!currentPlaylist) {
    return null
  }

  const i =
    currentPlaylist.currentIndex + 1 > currentPlaylist.elements.length - 1
      ? 0
      : currentPlaylist.currentIndex + 1

  const nextItem = currentPlaylist.elements[i]
  const button = nextItem.querySelector<HTMLButtonElement>(".js-track-play")
  button.click()
}

export function previousPlaylistItem(): HTMLElement | null {
  if (!currentPlaylist) {
    return null
  }

  const i =
    currentPlaylist.currentIndex == 0
      ? currentPlaylist.elements.length - 1
      : currentPlaylist.currentIndex - 1

  const nextItem = currentPlaylist.elements[i]
  const button = nextItem.querySelector<HTMLButtonElement>(".js-track-play")
  button.click()
}

if ("mediaSession" in navigator) {
  navigator.mediaSession.setActionHandler("nexttrack", function() {
    playNextPlaylistItem()
  })

  navigator.mediaSession.setActionHandler("previoustrack", function() {
    previousPlaylistItem()
  })
}
