import { on } from "delegated-events"
import { fetchSafeDocumentFragment } from "~/utils/fetch"

on("click", ".js-load-more", function(e) {
  const link = e.target
  if (!(link instanceof HTMLAnchorElement)) {
    return
  }

  e.preventDefault()

  fetchSafeDocumentFragment(document, link.href).then(html => {
    link.replaceWith(html)
  })
})
