import { on } from "delegated-events"

on("click", ".js-mobile-menu-toggle", function(e: Event) {
  const el = e.target as HTMLElement
  const menu = document.getElementById("mobile-menu")
  menu.hidden = !menu.hidden

  const icons = el.querySelectorAll("svg")
  icons[0].classList.toggle("hidden")
  icons[0].classList.toggle("block")
  icons[1].classList.toggle("hidden")
  icons[1].classList.toggle("block")
})
