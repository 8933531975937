import { on, fire } from "delegated-events"

on("keydown", "textarea", function(e) {
  if (e.metaKey && e.key === "Enter") {
    const form = e.target.closest("form")
    if (form) {
      if (fire(form, "submit", true)) {
        form.submit()
      }
    }
  }
})
