/*       */
import { observe } from "selector-observer"
import autosize from "@github/textarea-autosize"
import { on } from "delegated-events"
import invariant from "invariant"

function isValidForm(form) {
  for (const el of form.elements) {
    if (el instanceof HTMLFormElement) {
      if (!el.checkValidity()) {
        return false
      }
    }
  }

  return true
}

observe(".js-size-to-fit", function(el) {
  autosize(el)
})

on("click", "button[data-disable-with]", function(e) {
  const target = e.target
  const button = target.closest("button")
  const message = button.getAttribute("data-disable-with")
  if (confirm(message)) {
    return
  }

  e.preventDefault()
})

on("submit", "form", function(e) {
  const form = e.target
  invariant(form instanceof HTMLFormElement, "target not a form somehow")
  form.classList.toggle("is-disabled", !isValidForm(form))

  if (form.classList.contains("is-disabled")) {
    e.preventDefault()
    e.stopPropagation()

    setTimeout(() => {
      for (const el of form.querySelectorAll("[disabled]")) {
        el.removeAttribute("disabled")
      }
    }, 100)
  }
})
