import Rails from "rails-ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "activestorage"
import "@github/clipboard-copy-element"

import "../behaviors/comments"
import "../behaviors/forms"
import "../behaviors/fun"
import "../behaviors/load-more"
import "../behaviors/refresh"
import "../behaviors/quick-submit"
import "../behaviors/folders"
import "../behaviors/notes"
import "../behaviors/cover-art"
import "../behaviors/popover"
import "../behaviors/modal"
import "../behaviors/playlist"
import "../behaviors/remote-input"
import "../behaviors/dark-mode"
import "../behaviors/suggester"
import "../behaviors/tailwind"

import "bootstrap-icons/font/bootstrap-icons.css"

import "../components/TrackSidebar"
import "../components/TrackPlayer"
import "../components/PlayButton"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
