import { on } from "delegated-events"
import { fetchSafeDocumentFragment } from "~/utils/fetch"

on("refresh:partial", "[data-refresh-url]", function(e) {
  const target = e.target as HTMLElement
  const el = target.closest<HTMLElement>("[data-refresh-url]")
  refresh(el)
})

export async function refresh(el: HTMLElement): Promise<HTMLElement> {
  const url = el.getAttribute("data-refresh-url")
  const replacement = await fetchSafeDocumentFragment(document, url)
  el.replaceWith(replacement)
  return replacement
}
