import { observe } from "selector-observer"
import { fetchText } from "~/utils/fetch"
import { on } from "delegated-events"

function getContainerFromEvent(e) {
  const container = e.target.closest(".js-suggester-container")

  if (!container) {
    throw new Error("did not find container")
  }

  return container
}

function getResultsFromContainer(container) {
  const results = container.querySelector(".js-suggester-results")
  if (!results) {
    throw new Error("did not find container")
  }
  return results
}

function activate(e) {
  const container = getContainerFromEvent(e)
  const results = getResultsFromContainer(container)
  results.classList.remove("d-none")
}

function deactivate(e) {
  setTimeout(() => {
    const container = getContainerFromEvent(e)
    const results = getResultsFromContainer(container)
    results.classList.add("d-none")
  }, 100)
}

function fetchSuggestions(e) {
  const container = getContainerFromEvent(e)
  const url = container.getAttribute("data-url")
  if (!url) {
    return
  }

  const params = new URLSearchParams()
  if (e.target instanceof HTMLInputElement) {
    params.append("q", e.target.value)
  }

  fetchText(url + "?" + params.toString()).then(replacement => {
    const results = getResultsFromContainer(container)
    results.innerHTML = replacement
  })
}

on("click", ".js-suggester-suggestion", function(e) {
  const container = getContainerFromEvent(e)
  const input = container.querySelector(".js-suggester-field")
  input.value = e.target.value
})

observe(".js-suggester-field", function(el) {
  el.addEventListener("focus", activate)
  el.addEventListener("blur", deactivate)
  el.addEventListener("input", fetchSuggestions)
})
