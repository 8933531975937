import { observe } from "selector-observer"
import { fetchSafeDocumentFragment } from "~/utils/fetch"

// eslint-disable-next-line no-unused-vars
function debounce(callback: (e: Event) => Promise<void>): (e: Event) => void {
  let timeout: number
  return function(e: Event) {
    clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      clearTimeout(timeout)
      callback(e)
    }, 300)
  }
}

async function fetchResults(e: Event) {
  const target = e.target as HTMLElement
  const container = target.closest(".js-remote-input")
  const input = container.querySelector<HTMLInputElement>("input")

  const ownedID = container.getAttribute("aria-owns")
  const owned = document.getElementById(ownedID)

  const originalContent = owned.querySelector<HTMLElement>(".js-search-empty")
  const resultsContainer = owned.querySelector<HTMLElement>(".js-search-results")

  var q = input.value

  if (q.length === 0) {
    originalContent.removeAttribute("hidden")
    resultsContainer.innerHTML = ""
  }

  if (q.length < 3) {
    return
  }

  const src = container.getAttribute("data-src")

  const url = new URL(src, window.location.href)
  url.pathname = src
  url.searchParams.set("q", q)

  const html = await fetchSafeDocumentFragment(document, url)

  originalContent.hidden = true
  resultsContainer.innerHTML = ""
  resultsContainer.appendChild(html)
}

observe(".js-remote-input", {
  add: (el: HTMLElement) => {
    el.addEventListener("input", debounce(fetchResults))
  },
})
