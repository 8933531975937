import { on } from "delegated-events"
import { graphql } from "~/utils/graphql"
import { refresh } from "./refresh"

on("popover:show", ".js-notes-container", function(e: Event) {
  const target = e.target as HTMLElement
  const textfield = target.querySelector<HTMLTextAreaElement>(".js-note-textarea")
  textfield.focus()
})

on("submit", ".js-note-form", async function(e: Event) {
  e.preventDefault()
  const form = e.target as HTMLFormElement

  var body = form.querySelector("[name='note[body]']") as HTMLTextAreaElement
  var nodeId = form.querySelector("[name='note[notable_id]']") as HTMLInputElement
  await graphql(
    `
      mutation($body: String!, $notableID: ID!) {
        addNote(body: $body, notableID: $notableID) {
          __typename
        }
      }
    `,
    { body: body.value, notableID: nodeId.value }
  )

  const container = form.closest<HTMLElement>(".js-notes-container")
  await refresh(container)
})
