import { DirectUpload } from "@rails/activestorage"
import { fetchForm } from "~/utils/fetch"
import { observe } from "selector-observer"
import { fire, on } from "delegated-events"
import { dismissModal } from "./modal"

const inputsToFiles = new WeakMap<HTMLInputElement, File>()

async function handleFile(form: HTMLFormElement, file: File) {
  const input = form.querySelector("input[type=file]") as HTMLInputElement
  const previewContainer = form.querySelector(".js-cover-art-preview-container") as HTMLElement

  var img = document.createElement("img")
  img.src = URL.createObjectURL(file)
  img.classList.add("cover-art-preview")
  previewContainer.innerHTML = ""
  previewContainer.append(img)

  inputsToFiles.set(input, file)
  form.querySelector("button[type=submit]").removeAttribute("disabled")
}

on("change", ".js-cover-art-input", function(e: Event) {
  const target = e.target as HTMLInputElement
  const file = target.files[0]
  handleFile(target.form, file)
})

on("click", ".js-choose-image", function(e: Event) {
  const target = e.target as HTMLButtonElement
  const form = target.closest("form")
  const input = form.querySelector("input[type=file]") as HTMLInputElement
  input.click()
})

on("click", ".js-update-cover-art-button", function(e: Event) {
  e.preventDefault()
  const target = e.target as HTMLButtonElement
  const form = target.closest<HTMLFormElement>("form")
  const input = form.querySelector("input[type=file]") as HTMLInputElement
  const file = inputsToFiles.get(input)

  const url = input.dataset.directUploadUrl
  const token = input.dataset.directUploadToken
  const attachmentName = input.dataset.directUploadAttachmentName

  const upload = new DirectUpload(file, url, token, attachmentName)

  const button = form.querySelector("button[type=submit]") as HTMLButtonElement
  button.disabled = true

  upload.create(function(error, blob) {
    if (error) {
      // Handle the error
    } else {
      Array.from(form.querySelectorAll(`.js-cover-art-hidden-input`)).forEach(
        (el: HTMLInputElement) => {
          el.remove()
        }
      )
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement("input")
      hiddenField.classList.add("js-cover-art-hidden-input")
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("value", blob.signed_id)
      hiddenField.name = input.name
      form.appendChild(hiddenField)

      fetchForm(form).then(() => {
        const modalContainer = form.closest<HTMLElement>(".js-modal-container")
        dismissModal(modalContainer)
        button.disabled = true

        const coverArtContainer = form.closest(".js-cover-art-container")
        if (!coverArtContainer) {
          return
        }
        const coverArtRefreshable = coverArtContainer.querySelector(".js-cover-art-refreshable")
        if (coverArtRefreshable) {
          fire(coverArtRefreshable, "refresh:partial")
        }
      })
    }
  })
})

function dragOverHandler(e: DragEvent) {
  const target = e.target as HTMLElement
  target.classList.add("box-shadow")
  e.preventDefault()
}

function dragLeaveHandler(e: DragEvent) {
  const target = e.target as HTMLElement
  target.classList.remove("box-shadow")
}

function dropHandler(e: DragEvent) {
  const target = e.target as HTMLElement
  target.classList.remove("box-shadow")
  const file = e.dataTransfer.files[0]
  e.preventDefault()

  const form = target.closest("form")
  handleFile(form, file)
}

observe(".js-drop-zone", {
  add(el: HTMLElement) {
    el.addEventListener("dragover", dragOverHandler)
    el.addEventListener("dragleave", dragLeaveHandler)
    el.addEventListener("drop", dropHandler)
  },
  remove(el: HTMLElement) {
    el.removeEventListener("dragover", dragOverHandler)
    el.removeEventListener("dragleave", dragLeaveHandler)
    el.removeEventListener("drop", dropHandler)
  },
})
